::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background: grey; 
  border-radius: 20px;
}

.scroller {
  overflow-y: scroll;
}

.scroller {
  scrollbar-color: gray transparent;
  scrollbar-width: thin !important;
}

.contact__modal {
  position: fixed;
  top: 3.33%;
  bottom: 10.22%;
  left: 34%;
  min-height: 90vh;
  width: 423px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  outline: 0px;

  .error_wrapper {
    height: 45px;
    // background-color: blue;

    .error_text {
      margin-left: 50px;
      font-size: 15px;
      color: red;
    }
  }

  .card_modal__header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .not_deleted__wrapper {
      margin-left: 80px;
    }

    .small_button__wrapper {
      margin-left: 10px;
    }

    .profile__picture {
      height: 80px;
      width: 80px;
      border-radius: 40px;
      margin-top: 10px;
      margin-right: 35px;
      background-color: #acacac;
      display: flex;
      justify-content: center;
      align-items: center;

      .profile__picture__initials {
        color: rgba(254, 254, 255, 1);
        font-size: 30px;
      }
    }
  }

  .contact_modal__fullname_text {
    margin-top: 10px;
    align-self: center;
    color: rgba(0, 0, 0, 1);
    font-size: 30px;
  }
  .contact_modal_dispay {
    overflow-y: auto;
    .contact_modal__innner_wrapper {
      // background-color: blue;
      overflow-y: auto;
      flex: 1;
      margin: 0px 25px 10px 40px;
      padding-right: 15px;
    }

    .buttons__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      margin-left: 40px;
      margin-right: 40px;

      .divider {
        height: 10px;
      }
    }
  }
}

.contact__modal__overlay {
  position: fixed;
  top: 0;
  left: 251px;
  right: 0;
  bottom: 0;
  background: rgba(11, 10, 30, 0.8);
}
