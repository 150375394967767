.form__wrapper {
  .inputs__wrapper {
    margin-left: 20px;
    margin-right: 20px;

    input {
      margin-top: 36px;
      border: none;
      height: 24px;
      border-bottom-style: solid;
      width: 100%;
      border-width: 1px;
      border-color: rgba(227, 232, 240, 1);
      font-size: 16px;
      font-weight: 500;
      background-color: transparent;
    }

    input::placeholder {
      color: #344249;
      font-weight: 400;
      font-size: 16px;
    }

    input:focus {
      outline: none;
    }

    .creditcard__stripe__card_number {
      // margin-top: 36px;
      border: none;
      height: 24px;
      border-bottom-style: solid;
      width: 100%;
      border-width: 1px;
      border-color: rgba(227, 232, 240, 1);
    }

    .creditcard__stripe__card_exp_cvc {
      margin-top: 36px;
      border: none;
      height: 24px;
      border-bottom-style: solid;
      width: 100%;
      border-width: 1px;
      border-color: rgba(227, 232, 240, 1);
    }
  }

  .buttons__wrapper {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .button__wrapper {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
