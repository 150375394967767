.dashboard {
  min-height: 100vh;
  background-image: url('../../assets/dashboardBackground.png');
  background-repeat: no-repeat;
  background-size: cover;

  .dashboard_logo {
    padding-left: 40px;
    padding-right: 46px;
    padding-top: 30px;
    width: 165px;

    .dashboard_logo--image {
      width: 100%;
      object-fit: contain;
    }
  }

  .my_account__text {
    margin-top: 21px;
    text-align: left;
    padding-left: 40px;
  }

  .links__wrapper {
    margin-top: 40px;

    .link__container {
      margin-bottom: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 50px;
      padding-left: 50px;
      color: #4f4f4f;
      font-weight: 400;
      font-size: 16px;
      text-align: left;

      .call_icon {
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }

    .link__container:hover {
      background-color: white;
      border-left-style: solid;
      border-width: 2px;
      cursor: pointer;
      padding-left: 50px;
      color: black;
      font-weight: 700;
      font-size: 16px;

      p {
        cursor: pointer;
      }
    }

    .link__container__not__visible {
      display: none;
    }

    .link__container__selected {
      margin-bottom: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      background-color: white;
      border-left-style: solid;
      border-width: 2px;
      padding-left: 50px;
      color: black;
      font-weight: 700;
      font-size: 16px;
      text-align: left;

      .call_icon {
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }
  }
}

a {
  text-decoration: none;
}
