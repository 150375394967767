.ticket__modal {
  position: fixed;
  top: 15%;
  left: 34%;
  min-height: 60vh;
  width: 464px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  outline: 0px;

  .ticket_modal__header {
    margin-top: 10px;
    display: flex;

    .ticket_modal__headline__wrapper {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      margin-top: 20px;

      .ticket_modal__headline {
        margin-left: 45px;
        font-style: normal;
        font-size: 24px;
        font-weight: bold;
        color: #1e2e46;
      }
    }
  }

  .ticket__modal__inner__wrapper {
    display: flex;
    flex: 1;
    margin-left: 60px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: space-between;

    .info__label {
      font-size: 20px;
      color: rgba(172, 172, 172, 1);
    }

    .info__value_action {
      font-size: 18px;
      color: rgba(242, 153, 74, 1);
    }

    .info__value {
      font-size: 18px;
    }
  }
}
