.reset__password__page__wrapper3 {
  margin-top: 50px;
  margin-left: 3.68vw;
  margin-right: 9.72vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .inner__wrapper {
    width: 340px;
    margin-top: 27px;
    margin-left: 6.32vw;
    display: flex;
    flex-direction: column;

    .reset__password__headline {
      font-size: 26px;
      text-align: left;
    }
    .reset__password__text {
      color: #4f4f4f;
      font-size: 18px;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .separator {
      height: 30px;
    }

    .button__wrapper {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }
  }

  .your__passphrase__text {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
}
