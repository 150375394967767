.other_search_wrapper {
  height: 52px;
  width: 200px;
  margin-right: 20px;
  background-color: #f5f3f5;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.other_search_text {
  font-size: 18px;
  margin-left: 14px;
}

.dropdown_content_wrapper {
  position: absolute;
  top: 100%;
  background-color: #f5f3f5;
  width: 200px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  display: none;

  .dropdown_picker {
    height: 52px;
    background-color: #f5f3f5;
    border: none;
    border-top: 1px solid #bdbdbd;
    width: 200px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown_picker_last_item {
    height: 52px;
    background-color: #f5f3f5;
    border: none;
    border-top: 1px solid #bdbdbd;
    width: 200px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .dropdown_text {
    font-size: 18px;
  }

  .dropdown_picker:active {
    transform: scale(0.98);
  }

  .dropdown_picker_last_item:active {
    transform: scale(0.98);
  }

  .dropdown_picker:hover {
    cursor: pointer;
    p {
      cursor: pointer;
    }
  }

  .dropdown_picker_last_item:hover {
    cursor: pointer;
    p {
      cursor: pointer;
    }
  }
}

.dropdown_content_wrapper_not_visible {
  display: none;
}

.other_search_wrapper:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;

  p {
    cursor: pointer;
  }

  .dropdown_content_wrapper {
    display: flex;
    flex-direction: column;
  }
}
