.credit__card__make__default__modal {
  position: fixed;
  top: 3.33%;
  bottom: 10.22%;
  left: 34%;
  min-height: 546px;
  width: 460px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  outline: 0px;

  .modal__header {
    display: flex;

    .close__button__wrapper {
      margin-top: 10px;
    }

    .modal__header__text__wrapper {
      margin-top: 34px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      .modal__header__text {
        margin-left: 45px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .inner__wrapper {
    margin-top: 30px;
    margin-left: 15.86%;
    margin-right: 15.86%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .error_message_wrapper {
      margin-top: 10px;
      height: 10px;

      .error_message {
        color: #fc3030;
        font-size: 10px;
        margin-left: 5px;
      }

      .error_message_not_visible {
        display: none;
      }
    }

    .buttons_wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      margin-bottom: 100px;

      .button_wrapper {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(11, 10, 30, 0.8);
}
