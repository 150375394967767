.backArrow__button {
  align-self: flex-start;
  padding: 0;
  border: none;
  background-color: transparent;

  .backArrow {
    height: 23px;
    width: auto;
  }
}

.backArrow__button:hover {
  cursor: pointer;
}
