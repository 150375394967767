.scroller {
  overflow-y: scroll;
}

.scroller {
  scrollbar-color: gray transparent;
  scrollbar-width: thin !important;
}

.contacts_page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .contacts_page__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    // margin-right: 30.5vw;
    width: 790px;
    margin-top: 28px;

    .no_contacts__headline {
      align-self: center;
      font-size: 30px;
      text-align: left;
      color: #4f4f4f;
      margin-top: 90px;
    }

    .input__wraper {
      position: relative;
      display: flex;
      flex-direction: column;

      .contacts__search__input {
        height: 52px;
        background-color: #f5f3f5;
        border: none;
        border-radius: 14px;
        padding-left: 54px;
        font-size: 18px;
      }

      .contacts__search__input::placeholder {
        font-size: 18px;
        color: #828282;
      }

      .contacts__search__input:focus {
        outline: none;
      }
    }
    .contacts__content__wrapper {
      display: flex;

      flex: 1;
      margin-top: 40px;

      .contacts__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        border-right: 1px solid #acacac;
      }

      .priority__contacts__wrapper {
        flex: 1;
        flex-direction: column;
      }

      .contacts__headline {
        align-self: center;
        font-size: 20px;
        font-weight: 700;
        color: #4f4f4f;
        margin-bottom: 20px;
      }

      .contacts__list {
        overflow-y: auto;
        height: 60vh;
        padding-right: 20px;
      }

      .priority__contacts__list {
        overflow-y: auto;
        height: 60vh;
        padding-left: 20px;
      }
    }

    .input__wraper:before {
      content: '';
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      width: 20px;
      background: url('../../../assets/search.png') center / contain no-repeat;
    }
  }
}
