.subscription__authcard__wrapper {
  background-color: white;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.subscription__authcard__wrapper__with__background1 {
  background-image: url('../../../assets/Card1.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.subscription__authcard__wrapper__with__background2 {
  background-image: url('../../../assets/Card2.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}
.subscription__authcard__wrapper__with__background3 {
  background-image: url('../../../assets/Card3.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.subscription__authcard__wrapper__with__background4 {
  background-image: url('../../../assets/Card4.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.subscription__authcard__wrapper__with__background5 {
  background-image: url('../../../assets/Card5.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 24px;
  height: 528px;
  width: 350px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;

  .subscription__card__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .subscription__type__text {
      text-align: right;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    .subscription__price__wrapper {
      margin-top: 35px;
      padding-bottom: 10px;
      display: flex;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: #e2e2e2;

      .subscription__price__text {
        font-size: 56px;
        font-family: 'TT Firs Neue';
        text-align: left;
      }

      .subscription__price__period {
        margin-left: 10px;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .discount__text__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .discount__text {
        color: #00b44c;
        font-size: 16px;
        text-align: right;
      }
    }
  }
}

.subscription__authcard__wrapper:hover {
  cursor: pointer;
  p {
    cursor: pointer;
  }
}
.subscription__authcard__wrapper__with__background1:hover {
  cursor: pointer;
  p {
    cursor: pointer;
  }
}
.subscription__authcard__wrapper__with__background2:hover {
  cursor: pointer;
  p {
    cursor: pointer;
  }
}
.subscription__authcard__wrapper__with__background3:hover {
  cursor: pointer;
  p {
    cursor: pointer;
  }
}
