.page__wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;

  .background__image__wrapper {
    background-image: url('../../assets/backgroundImage1.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 55.83vw;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .background__image__content {
      height: 100%;
      margin-top: 60px;
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .logo {
        width: auto;
        height: 360px;
      }

      .phrase__text {
        text-align: left;
        white-space: pre-wrap;
        font-size: 46px;
        margin-top: 200px;
      }
    }
  }

  .content__wrapper {
    // background-color: brown;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 1200px) {
  .page__wrapper {
    .background__image__wrapper {
      .background__image__content {
        .logo {
          width: auto;
          height: 300px;
        }
      }
    }
  }
}
