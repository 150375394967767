.questionpage__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .questionpage__inner__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 6.45vh;

    p {
      font-size: 20px;
    }

    a {
      font-size: 20px;
      color: rgba(0, 0, 0, 1);
    }

    .first_part {
      height: 120px;
      border-bottom: 1px solid #bdbdbd;
      width: 560px;

      .text__wrapper {
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .second_part {
      margin-top: 5.55vh;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #bdbdbd;
      width: 560px;

      .second_part_text_wrapper {
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .second_part_text_wrapper:first-child {
        text-align: left;
      }

      .button_wrapper {
        width: 310px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 5.55vh;
      }
    }

    .feedback_sent__text {
      margin-top: 50px;
      font-size: 24px;
      text-align: left;
    }

    .third_part {
      margin-top: 5.55vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 560px;

      .button_wrapper {
        width: 310px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }

      .message__input {
        margin-top: 20px;
        align-self: stretch;
        flex: 1;
        font-family: 'Roboto';
        border-radius: 14px;
        font-size: 18px;
        border: none;
        background-color: #f5f3f5;
        padding-top: 15px;
        padding-left: 20px;
      }
    }

    textarea {
      resize: none;
    }

    textarea::placeholder {
      color: rgba(189, 189, 189, 1);
      font-family: 'Roboto';
    }

    textarea:focus {
      outline: none;
    }
  }
}
