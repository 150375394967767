.ticket__wrapper {
  width: 343px;
  height: 56px;
  background: #f5f3f5;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  margin-right: 20px;
  margin-bottom: 20px;

  .ticketID__label {
    font-size: 20px;
    margin-left: 20px;
  }

  .ticket__status_red {
    margin-right: 10px;
    width: 130px;
    height: 36px;
    background: #f53a3a;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ticket__status_green {
    margin-right: 10px;
    width: 130px;
    height: 36px;
    background: #00b44c;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ticket__status_orange {
    margin-right: 10px;
    width: 130px;
    height: 36px;
    background: rgba(255, 137, 32, 1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ticket__status__text_14 {
    color: white;
    font-size: 14px;
  }

  .ticket__status__text_13 {
    color: white;
    font-size: 13px;
  }

  .ticket__status__text_12 {
    color: white;
    font-size: 12px;
  }
}

.ticket__wrapper:active {
  transform: scale(0.98);
}
