.registrationsecond__page__wrapper {
  min-height: 100vh;
  width: 100vw;
  background-image: url('../../assets/background2.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;

  .inner__wrapper {
    display: flex;
    flex: 1;
    margin-top: 60px;
    margin-left: 11.46vw;
    margin-right: 11.46vw;
    margin-bottom: 124px;
  }
}
