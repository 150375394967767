.registration__header__wrapper {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;

  .button__wrapper__back {
    display: flex;
    align-items: center;
    padding-left: 13px;
  }

  .button__wrapper__next {
    display: flex;
    flex-direction: column;
    width: 150px;
  }

  .header__text {
    font-size: 82px;
    margin-left: 150px;
    font-family: 'TT Firs Neue';
  }
}
