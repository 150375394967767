.address__info__label__wrapper {
  width: 100%;
  background-color: #eeeeee;
  border-radius: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;

  .address__info__label__inner_wrapper {
    padding-left: 20px;
    padding-top: 10px;

    .label__name__text {
      color: rgba(130, 130, 130, 1);
      font-size: 16px;
    }

    .value_text {
      font-size: 22px;
    }
  }
}
