.small_button {
  width: 70px;
  height: 30px;
  border-radius: 20px;
  background-color: $darkGrey;
  border: none;
  color: white;
}

.small_button:active {
  transform: scale(0.98);
}

.small_button:hover {
  cursor: pointer;
}
