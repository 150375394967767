.creditcards_page__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .credit_cards__wrapper {
    margin-top: 70px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-content: flex-start;

    // background-color: cadetblue;

    .credit_card__wrapper {
      width: 315px;
      margin-right: 40px;
      margin-bottom: 40px;
      border: none;
      background-color: $transparent;
    }

    .credit_card__wrapper:active {
      transform: scale(0.98);
    }
  }
}

.credit_card__wrapper:hover {
  cursor: pointer;

  p {
    cursor: pointer;
  }
}
