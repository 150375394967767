.signup__choose__plan__page__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .frequencyswitch__wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .subscriptioncard__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
