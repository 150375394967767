.social_buttons_wrapper {
  display: flex;
  justify-content: space-between;

  .social_button {
    background-color: #f5f3f5;
    border-radius: 20px;
    height: 56px;
    width: 108px;
    border: none;

    .google_logo_img {
      width: 20px;
      height: 21px;
    }

    .facebook_logo_img {
      width: 12px;
      height: 23px;
    }

    .apple_logo_img {
      width: 20px;
      height: 24px;
    }
  }

  .social_button:active {
    transform: scale(0.95);
  }

  .social_button:hover {
    cursor: pointer;
  }
}
