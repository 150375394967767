.profile_input_wrapper {
  width: 100%;
  background-color: #eeeeee;
  height: 34px;
  border-radius: 14px;
  margin-bottom: 10px;
  display: flex;

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    border: none;
    height: 100%;
    background-color: transparent;
    padding-right: 0;
    width: 100px;
    margin-right: 20px;
    outline: none;
  }

  .label__wrapper_long {
    width: 290px;
    display: flex;
    align-items: center;

    .add_delete__button {
      border: none;
      padding: 0px;
      width: 20px;
      height: 20px;
      margin-left: 7px;
      background-color: $transparent;

      .button__image {
        width: 20px;
        height: 20px;
      }
    }

    .add_delete__button:active {
      .button__image {
        transform: scale(0.95);
      }
    }

    .label__text {
      text-align: left;
      margin-left: 10px;
      color: #828282;
      font-family: 'Roboto';
      font-size: 16px;
    }
  }

  .label__wrapper {
    width: 180px;
    display: flex;
    align-items: center;

    .add_delete__button {
      border: none;
      padding: 0px;
      width: 20px;
      height: 20px;
      margin-left: 7px;
      background-color: $transparent;

      .button__image {
        width: 20px;
        height: 20px;
      }
    }

    .add_delete__button:active {
      .button__image {
        transform: scale(0.95);
      }
    }

    .label__text {
      text-align: left;
      margin-left: 10px;
      color: #828282;
      font-family: 'Roboto';
      font-size: 16px;
    }
  }

  .text__input__field {
    background-color: $transparent;
    border: none;
    height: 34px;
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%;
    font-size: 20px;
  }

  .text__input__field:focus {
    outline: none;
  }
}
