.profile__page__outer__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .profile_page__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 44px;

    .photo_input {
      // height: 130px;
      width: 130px;
      // border-radius: 65px;
      // background-color: #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .profile_picture {
        height: 130px;
        width: 130px;
        border-radius: 65px;
        object-fit: cover;
      }

      .profile_picture:hover {
        cursor: pointer;
      }

      .profile_picture:active {
        transform: scale(0.98);
      }

      .initials__text {
        font-size: 20px;
      }
    }

    .content__wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex: 1;
      margin-right: 80px;

      .fullname__wrapper {
        width: 343px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .inputs_wrapper {
      // height: 535px;
      overflow-y: auto;
      width: 343px;
      display: flex;
      flex-direction: column;
      padding-right: 10px;

      .inputs__inner__wrapper {
        margin-bottom: 30px;
      }
    }

    .links__wrapper {
      height: 535px;
      width: 343px;
      display: flex;
      flex-direction: column;

      .payment__type__link__wrapper:first-of-type {
        margin-bottom: 20px;
      }

      .payment__type__link__wrapper {
        display: flex;
        flex-direction: column;
        background: #eeeeee;
        border-radius: 14px;

        .payment__type__link__inner__wrapper {
          margin: 10px 20px 20px 20px;
          display: flex;
          flex-direction: column;

          .label__text {
            color: rgba(130, 130, 130, 1);
            font-size: 16px;
            font-family: 'Roboto';
            text-align: left;
          }

          .value_text {
            font-size: 20px;
            text-align: left;
            margin-top: 10px;
          }

          .expiration__wrapper {
            margin-top: 20px;
          }

          .credit_card_number {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(43, 46, 48, 1);
            font-family: 'Roboto';
            text-align: left;
            word-spacing: 5px;
          }

          .button__wrapper {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .submit_button__wrapper {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        flex: 1;

        justify-content: flex-end;
      }
    }
  }
}
