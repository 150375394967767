.dropdownarrow__wrapper {
  border: none;
  margin-left: 10px;
  margin-right: 20px;
  background-color: transparent;

  .dropdownarrow__wrapper_image_up {
    width: 14px;
    height: 7px;
  }

  .dropdownarrow__wrapper_image_down {
    width: 14px;
    height: 7px;
    transform: rotate(180deg);
  }
}

.dropdownarrow__wrapper:active {
  transform: scale(0.9);
}

.dropdownarrow__wrapper:hover {
  cursor: pointer;
}
