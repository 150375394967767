.page_wrapper {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
}

.page_wrapper__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 30px;
  padding-top: 30px;

  .page_wrapper__content--wrapper {
    flex: 1;
    display: flex;
  }
}

.page_wrapper_subscription {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/background2.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
