.contact__page__wrapper {
  border: none;
  width: 360px;
  border-radius: 14px;
  margin-bottom: 10px;
  background-color: $transparent;
  .contact__deleted {
    @extend .contact__wrapper;
    background-color: gray !important ;
  }
  .contact__wrapper {
    height: 60px;
    background-color: #f5f3f5;
    border-radius: 14px;

    display: flex;
    align-items: center;

    .contact__circle__wrapper {
      height: 47.72px;
      width: 47.72px;
      border-radius: 23.86px;
      background-color: #acacac;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .initials__text {
        color: #fefeff;
        font-size: 20px;
      }
    }

    .contact__info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;

      .full__name__text {
        font-size: 18px;
        text-align: left;
      }

      .email__text {
        font-size: 14px;
        height: 14px;
        color: rgba(79, 79, 79, 1);
        text-align: left;
      }
    }
  }
}

.contact__page__wrapper:active {
  transform: scale(0.99);
}

.contact__page__wrapper:hover {
  cursor: pointer;

  p {
    cursor: pointer;
  }
}
