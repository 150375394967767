.subscriptiontext__wrapper {
  .subscription__text {
    font-size: 16px;
    margin-top: 25px;
    text-align: left;
  }

  .subscription__text__small__margin {
    font-size: 16px;
    margin-top: 15px;
    text-align: left;
  }
}
