.call__modal {
    width: 25%;
    height: 750px;
    position: fixed;
    top: 10%;
    left: 40%;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    z-index: 3;

    .call__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #1E2E46;
            margin-top: 40px;
        }

        .call__in_call {
            margin-bottom: 15px;
        }

        .call__dots {
            margin-top: 5px;
            margin-bottom: 0;
        }

        .call__input_container {
            width: 90%;
            height: 83px;
            margin-top: 40px;

            .call__input {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow-wrap: anywhere;
                
                p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: #1E2E46;
                    margin: 0;
                }
            }
        }

        .call__digits_container {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 10%;

            .call__digits_row {
                width: 100%;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #000000;
                border-left: 1px solid #000000;
                border-right: 1px solid #000000;

                .call__digit {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: #1E2E46;
                    border-left: 1px solid #000000;
                    border-right: 1px solid #000000;
                    cursor: pointer;
                    user-select: none;
                }

                .call__digit:first-of-type {
                    border: none;
                }

                .call__digit:last-of-type {
                    border: none;
                }
            }

            .call__digits_row:first-of-type {
                border-top: 1px solid #000000;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .call__digits_row:last-of-type {
                border-bottom: 1px solid #000000;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

        img {
            width: 100px;
            height: 100px;
            margin-top: 8%;
            cursor: pointer;
        }
    }
}
