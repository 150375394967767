.ticket_page {
  display: flex;
  flex: 1;
  flex-direction: column;

  .tickets__page__wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 28px;

    .search__wrapper {
      height: 190px;
      border-bottom: 1px solid #bdbdbd;
      margin-right: 30px;

      .other__types__searches {
        margin-top: 20px;
        display: flex;
        height: 52px;

        .react-datepicker-wrapper {
          display: flex;
          width: 200px;

          .react-datepicker__input-container {
            .date_picker {
              height: 52px;

              margin-right: 20px;
              background-color: #f5f3f5;
              border-radius: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: none;
              padding-left: 20px;
            }

            input:focus {
              outline: none;
            }

            input::placeholder {
              font-size: 18px;
              color: black;
            }
          }
        }
      }

      .input__wraper {
        position: relative;
        display: flex;
        flex-direction: column;

        .contacts__search__input {
          max-width: 760px;
          height: 52px;
          background-color: #f5f3f5;
          border: none;
          border-radius: 14px;
          padding-left: 54px;
          font-size: 18px;
        }

        .contacts__search__input::placeholder {
          font-size: 18px;
          color: #828282;
        }

        .contacts__search__input:focus {
          outline: none;
        }
      }
    }

    .tickets__wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-top: 40px;
    }
    .input__wraper:before {
      content: '';
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      width: 20px;
      background: url('../../../assets/search.png') center / contain no-repeat;
    }
  }
}
