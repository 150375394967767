.auth__input__wrapper {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .auth__input__black {
    display: flex;
    background-color: #f5f3f5;
    height: 56px;
    border: none;
    padding-left: 20px;
    border-radius: 20px;
    font-size: 19px;
  }

  .auth__input__red {
    background-color: #f5f3f5;
    display: flex;
    color: red;
    height: 56px;
    border: none;
    padding-left: 20px;
    border-radius: 20px;
    font-size: 19px;
  }

  .error__wrapper {
    margin-top: 2px;
    height: 13px;
    .error__auth__text__visible {
      font-size: 13px;
      color: #f53a3a;
      text-align: right;
    }

    .error__auth__text__not__visible {
      display: none;
    }
  }
}
.auth__input__black::placeholder {
  color: #acacac;
}

.auth__input__black:focus {
  outline: none;
  color: black;
}

.auth__input__red:focus {
  outline: none;
  color: black;
}
