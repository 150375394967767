.welcome__text {
    margin-bottom: 10px;
    align-self: flex-start;
    font-size: 20px;
}

.forgot__password__button {
    align-self: flex-start;
    padding: 0px;
    margin-bottom: 10px;
    border: none;
    background-color: transparent;

    .forgot__password__text {
      color: #acacac;
      align-self: flex-end;
      font-size: 18px;
    }
}