.resetpassword2__page__wrapper {
  min-height: 100vh;
  width: 100vw;
  background-image: url('../../../assets/background3.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  flex: 1;

  .logo__phrase__wrapper {
    width: 55.83vw;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .logo__phrase__wrapper__content {
      height: 100%;
      margin-top: 60px;
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .logo {
        width: auto;
        height: 360px;
      }

      .phrase__text {
        text-align: left;
        white-space: pre-wrap;
        font-size: 46px;
        margin-top: 200px;
      }
    }
  }
  .content__wrapper {
    margin-top: 50px;
    margin-left: 3.68vw;
    margin-right: 9.72vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .inner__wrapper {
      width: 340px;
      margin-top: 27px;
      margin-left: 6.32vw;
      display: flex;
      flex-direction: column;

      .reset__password__headline {
        font-size: 26px;
        text-align: left;
      }
      .reset__password__text {
        color: #4f4f4f;
        font-size: 18px;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .button__wrapper {
        display: flex;
        flex-direction: column;
      }

      .yes_logo {
        margin-top: 100px;
        height: 115px;
        width: 145px;
        background-image: url('../../../assets/yes_logo.png');
        background-repeat: no-repeat;
        background-size: cover;
        align-self: center;
      }
    }
  }
}
