.App {
  text-align: center;

  .redirect_page__wrapper {
    display: none;
  }
}

@media only screen and (max-width: 962px) {
  .App {
    .routes {
      display: none;
    }

    .redirect_page__wrapper {
      display: flex;
      flex: 1;
      height: 100vh;
    }
  }
}
