.signup__passphrase__page__wrapper {
  margin-top: 50px;
  margin-left: 3.68vw;
  margin-right: 9.72vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .inner__wrapper {
    width: 340px;
    margin-top: 172px;
    margin-left: 6.32vw;
    display: flex;
    flex-direction: column;

    .button__wrapper {
      margin-top: 228px;
      display: flex;
      flex-direction: column;
    }

    .error_message__wrapper {
      height: 12px;
      .error_message_text {
        text-align: left;
        font-size: 12px;
        color: #f53a3a;
      }
    }
  }

  .your__passphrase__text {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
}
