.remove__button__button {
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: 10px;

  .remove__butoon__image {
    height: 35px;
    width: 35px;
  }
}

.remove__button__button:hover {
  cursor: pointer;
}
