.subscription_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .subsciption__plan__page__wrapper {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    flex: 1;

    .frequency__switch__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 90px;
      width: 232px;
      background-color: white;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 24px;

      .frequency__switch__text {
        margin-top: 11px;
        margin-bottom: 11px;
        font-size: 16px;
        color: #4f4f4f;
      }
    }

    .subsciption__plan__page__inner__wrapper {
      display: flex;
      flex: 1;
      margin-top: 20px;
      margin-right: 30px;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 680px) and (max-width: 1300px) {
  .subscription__main__wrapper {
    width: 22vw !important;
  }

  .subscription__main__wrapper__with__background1 {
    width: 22vw !important;
  }

  .subscription__main__wrapper__with__background2 {
    width: 22vw !important;
  }

  .subscription__main__wrapper__with__background3 {
    width: 22vw !important;
  }
}