.edit_contacts_wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .edit_contacts_inner_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;

    .basic_inputs_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .fullname__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .notes_wrapper {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .inputs_wrapper {
        padding-right: 0px;
        margin-top: 30px;

        .inputs__inner__wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
