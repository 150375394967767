.credit__card__modal {
  position: fixed;
  top: 3.33%;
  bottom: 10.22%;
  left: 34%;
  min-height: 796px;
  width: 460px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  outline: 0px;

  .modal__header {
    display: flex;

    .close__button__wrapper {
      margin-top: 10px;
    }

    .modal__header__text__wrapper {
      margin-top: 34px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      .modal__header__text {
        margin-left: 45px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .inner__wrapper {
    margin-left: 15.86%;
    margin-right: 15.86%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .modal__text {
      margin-top: 30px;
      font-size: 14px;
      line-height: 20px;
      color: #869aa5;
    }

    .form__wrapper {
      margin-top: 20px;
    }

    .credit__card__front__wrapper {
      margin-top: 20px;
      display: flex;
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(11, 10, 30, 0.8);
}

.remove__button__button:active {
  transform: scale(0.98);
}
