@import './variables.scss';
@import '../App.scss';

//Templates
@import '../templates/RegistrationTemplate/RegistrationTemplate.scss';
@import '../templates//AppTemplate//AppTemplate.scss';
@import '../templates/RegistrationSecondPartTemplate/RegistrationSecondPartTemplate.scss';

//Pages
@import '../pages/MainApp/ContactsPage/ContactsPage.scss';
@import '../pages/MainApp/TicketsPage/TicketsPage.scss';
@import '../pages/MainApp/SubscriptionPlanPage/SubscriptionPlanPage.scss';
@import '../pages/MainApp/QuestionsPage/QuestionsPage.scss';
@import '../pages/MainApp/ProfilePage/ProfilePage.scss';
@import '../pages/MainApp/CreditCardsPage/CreditCardsPage.scss';

@import '../pages/Authentication/LoginPage/LoginPage.scss';
@import '../pages/Authentication/ResetPasswordPage/ResetPasswordPage.scss';
@import '../pages/Authentication/ResetPasswordPage2/ResetPasswordPage2.scss';
@import '../pages/Authentication/ResetPasswordPage3/ResetPasswordPage3.scss';
@import '../pages/Authentication/SignUpChoosePlanPage/SignUpChoosePlanPage.scss';
@import '../pages/Authentication/SignUpNameNumberPage/SignUpNameNumberPage.scss';
@import '../pages/Authentication/SignUpPassphrasePage/SignUpPassphrasePage.scss';
@import '../pages/Authentication/SignUpPaymentTypePage/PaymentTypePage.scss';
@import '../pages/Authentication/SignupUpMainPage/SignupMainPage.scss';

//Redirect
@import '../pages/Redirect/RedirectPage/RedirectPage.scss';

// **Components

//Subscriptions
@import '../components/subscriptions/SubscriptionMainCard/SubscriptionMainCard.scss';
@import '../components/subscriptions/SubscriptionTextMain/SubscriptionTextMain.scss';
@import '../components/subscriptions/PlanChooser/PlanChooser.scss';

//Header
@import '../components/headers/MainHeader/MainHeader.scss';

//Inputs
@import '../components/inputs/AuthInput/AuthInput.scss';
@import '../components/inputs/ProfileInput/ProfileInput.scss';
@import '../components/inputs/AuthNameInput/AuthNameInput.scss';
@import '../components/inputs/AuthNoteInput/AuthNoteInput.scss';

//Credit Cards
@import '../components/creditCards/AddCreditCard/AddCreditCard.scss';

//Modals
@import '../components/modals/CreditCardMakeDefaultModal/CreditCardMakeDefaultModal.scss';
@import '../components/modals/TicketModal/TicketModal.scss';
@import '../components/contacts/EditContact/EditContact.scss';

//Buttons
@import '../components/buttons/DropdownButton/DropDownButton.scss';
@import '../components/buttons/SocialMediaButtons/SocialMediaButtons.scss';
@import '../components/buttons/SmallButton/SmallButton.scss';
@import '../components/buttons/RegularButton/RegularButton.scss';

//Tickets
@import '../components/tickets/Ticket/Ticket.scss';
@import '../components/dropdown/DropdownTicket/DropdownTicket.scss';

//Contacts
@import '../components/contacts/Contact/Contact.scss';

//Labels
@import '../components/labels/NotesInfoLabel/NotesInfoLabel.scss';
body {
  overflow-x: hidden;
}
