.creditCard__front {
  background-image: url('../../../assets/creditCardFront.png');
  background-repeat: no-repeat;
  background-size: cover;

  height: 200px;
  width: 100%;
  box-shadow: 0px 18px 80px rgba(0, 0, 0, 0.08),
    0px 4.02054px 17.869px rgba(0, 0, 0, 0.0476886),
    0px 1.19702px 5.32008px rgba(0, 0, 0, 0.0323114);

  border-radius: 16px;
  display: flex;

  .creditcard__inner__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 26px;

    .creditcard__logo_default_wrapper {
      display: flex;

      justify-content: space-between;

      .default_card_text {
        margin-right: 30px;
      }

      .creditcard__logo__wrapper {
        height: 32px;
        width: 100px;
        margin-bottom: 38px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .visaCard {
          height: 32px;
          width: 100px;
        }

        .masterCard {
          height: 30px;
          width: 48px;
        }
      }
    }

    .creditcard__number__text {
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      color: #2b2e30;
      word-spacing: 5px;
      letter-spacing: 0.3em;
    }

    .info__wrapper {
      margin-top: 10px;
      display: flex;
      flex: 1;

      p {
        text-align: left;
        font-size: 12px;
        color: #869aa5;
      }

      .cardholder__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .exiprydate__wrapper {
        margin-left: 39px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
