.signup__name__number__page__wrapper {
  margin-top: 50px;
  margin-left: 3.68vw;
  margin-right: 9.72vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .inner__wrapper {
    width: 340px;
    margin-top: 137px;
    margin-left: 6.32vw;
    display: flex;
    flex-direction: column;

    .error__wrapper {
      height: 10px;

      .error__text {
        color: red;
        font-size: 12px;
        text-align: left;
        padding-left: 7px;
      }
    }

    .button__wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  .your__name__text {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .number__text {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
}

.backArrow__button:active {
  transform: scale(0.8);
}
