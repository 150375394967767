.add__credit__card__wrapper {
  display: flex;
  height: 200px;
  width: 340px;
  border: none;
  background-color: rgba(18, 18, 29, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .plus__text {
    font-size: 35px;
    font-weight: 200;
  }

  .add__card__text {
    font-weight: 700;
    font-size: 14px;
  }
}

.add__credit__card__wrapper:hover {
  cursor: pointer;
}
