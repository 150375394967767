.whiteButton {
  background-color: white;
  height: 56px;
  border-width: 1px;
  border-radius: 20px;
  font-size: 24px;
}
.blackButton {
  background-color: black;
  color: white;
  height: 56px;
  border: none;
  border-radius: 20px;
  font-size: 24px;
}

.darkGreyButton {
  background-color: #828282;
  height: 56px;
  border-width: 1px;
  border-radius: 20px;
  font-size: 24px;
  color: #ffffff;
}

.lightGreyButton {
  background-color: #d9d9d9;
  height: 56px;

  border: none;
  border-radius: 20px;
  font-size: 24px;
  color: #ffffff;
}

.redButton {
  background-color: #fc3030;
  height: 56px;
  border: none;
  border-radius: 20px;
  font-size: 24px;
  color: #ffffff;
}

.blackButton:active {
  transform: scale(0.98);
}

.whiteButton:active {
  transform: scale(0.98);
}

.redButton:active {
  transform: scale(0.98);
}

.lightGreyButton:active {
  transform: scale(0.98);
}

.darkGreyButton:active {
  transform: scale(0.98);
}

.blackButton:hover {
  cursor: pointer;
}

.whiteButton:hover {
  cursor: pointer;
}

.redButton:hover {
  cursor: pointer;
}

.lightGreyButton:hover {
  cursor: pointer;
}

.darkGreyButton:hover {
  cursor: pointer;
}
