.payment__type__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .payment__type__inner__wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .credit_card_button__wrapper {
      border: none;
      padding: 0;
      width: 100%;
      background-color: $transparent;
    }

    .credit_card_button__wrapper:hover {
      cursor: pointer;
    }

    .credit_card_button__wrapper:active {
      transform: scale(0.98);
    }

    .frequency__switch__wrapper {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 127px;
      width: 340px;
      background-color: white;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 24px;

      .frequency__switch__text {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 16px;
        color: #4f4f4f;
      }
    }

    .apple__google__pay__wrapper {
      display: flex;
      width: 340px;
      margin-top: 50px;
      margin-bottom: 50px;

      .apple__button {
        background-color: transparent;
        border: none;
        padding-left: 50px;
        padding-right: 50px;
        border-right-style: solid;
        border-width: 1px;
        border-color: rgba(211, 211, 211, 0.8);

        .apple__logo__png {
          height: 30px;
          width: 75px;
        }
      }

      .google__button {
        background-color: transparent;
        border: none;
        padding-left: 50px;
        padding-right: 50px;

        .google__logo__png {
          height: 30px;
          width: 75px;
        }
      }
    }
  }
}

.apple__button:active {
  .apple__logo__png {
    transform: scale(0.98);
  }
}

.google__button:active {
  .google__logo__png {
    transform: scale(0.98);
  }
}

.add__credit__card__wrapper:active {
  transform: scale(0.98);
}

.error__text {
  margin-top: 22px;
  height: 13px;
  font-size: 16px;
  color: #f53a3a;
}