@import './components/LoginCredentialsComponent/LoginCredentialsComponent.scss';
@import './components/LoginPassphraseComponent/LoginPassphraseComponent.scss';

.login__page__wrapper {
  margin-top: 220px;
  width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  // .welcome__text {
  //   margin-bottom: 10px;
  //   align-self: flex-start;
  //   font-size: 20px;
  // }

  // .forgot__password__button {
  //   align-self: flex-start;
  //   padding: 0px;
  //   margin-bottom: 10px;
  //   border: none;
  //   background-color: transparent;

  //   .forgot__password__text {
  //     color: #acacac;
  //     align-self: flex-end;
  //     font-size: 18px;
  //   }
  // }

  .forgot__password__button:hover {
    .forgot__password__text {
      color: #757474;

      cursor: pointer;
    }
  }

  .forgot__password__button:active {
    .forgot__password__text {
      transform: scale(0.98);
    }
  }

  .missing__account__text {
    font-size: 16px;
    margin-top: 72px;
    margin-bottom: 10px;
    text-align: left;
  }

  .password_req_error__wrapper {
    margin-top: 10px;
    .password_req__error {
      color: red;
      font-size: 10px;
      text-align: left;
    }
  }

  .sign_in_label {
    font-size: 18px;
    color: #4f4f4f;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
